export default {
  data () {
    return {
      controller: null,
      infinityScroll: false,
      totalOfPages: 1,
      totalCount: null,
      page: 1,
      preventMultipleCalls: true
    }
  },
  destroyed () {
    this.removeListeners()
  },
  methods: {
    onScroll (event, isDocument = false) {
      if (this.page < Number(this.totalOfPages) && event) {
        const scrollTop = isDocument
          ? window.scrollY : event.target.scrollTop
        const scrollHeight = isDocument
          ? window.scrollMaxY ||
          Math.round(document.documentElement.scrollHeight - document.documentElement.clientHeight)
          : event.target.scrollHeight - event.target.clientHeight

        if ((scrollTop + (window.innerHeight / 2)) > scrollHeight && !this.preventMultipleCalls) {
          this.infinityScroll = true
          this.page++
          this.preventMultipleCalls = true
          this.getData()
        }
      }
    },
    async setListener (containerName) {
      this.controller = new AbortController()
      const container = document.querySelector(`.${containerName}`)
      window.addEventListener('scroll', ($event) => this.onScroll($event, true), { signal: this.controller.signal })
      container.addEventListener('scroll', this.onScroll, { signal: this.controller.signal })
    },
    removeListeners () {
      this.controller.abort()
    }
  }
}
