<template>
  <DiaryContainer
    :title="$translations.diary.title"
    :actions="actions"
    max-width="1008px"
  >
    <template v-slot:banner>
      <!-- skeleton -->
      <Skeleton
        v-if="loading"
        class="skeleton-banner"
        height="124px"
        border-radius="16px"
      />

      <RecommendationBanner
        v-if="!loading && bannerData?.title"
        :title="bannerData.title[lang]"
        :content="bannerData.recommended_phrase[lang]"
        :icon-url="bannerData.icon"
      />
      <MoodBanner label-style="purple" v-if="!loading && !bannerData" />
    </template>

    <template v-slot:content>
      <!-- skeleton -->
      <div v-if="loading">
        <div v-for="index in 1" :key="index" class="page-segment">
          <div class="page-segment-content">
            <Skeleton
              class="margin-bottom-lg"
              width="100px"
              height="20px"
              border-radius="16px"
            />
            <div class="skeleton-content">
              <div class="date">
                <Skeleton
                  v-for="index in 2"
                  :key="index"
                  height="24px"
                  width="21px"
                  border-radius="16px"
                />
              </div>
              <div class="cards">
                <Skeleton width="100%" height="200px" border-radius="16px" />
                <Skeleton width="100%" height="200px" border-radius="16px" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- content -->
      <div class="padding-bottom" v-if="!loading">
        <template v-for="(item, key, index) in entriesFiltered">
          <div
            class="page-segment"
            :key="index"
            v-if="!showDatazero"
          >
            <div class="page-segment-content">
              <div class="entries-entry">
                <div class="entries-entry-month heading-small" v-text="key" />
                <div
                  class="entries-entry-day"
                  v-for="(entry, i) in item"
                  :key="i"
                >
                  <div class="entries-entry-day-label">
                    <div
                      class="content-title"
                      v-text="dateRow(entry.created_at, item, i).number"
                    />
                    <div
                      class="body-small text-gray-50"
                      v-text="dateRow(entry.created_at, item, i).name"
                    />
                  </div>
                  <DiaryCard :data="entry" />
                </div>
              </div>
            </div>
          </div>
        </template>

        <LoaderSimple
          class="margin-y"
          v-if="infinityScroll && data && data.length >= 10"
        />

        <DataZeroSimple
          v-if="!loading && showDatazero"
          :html="$translations.diary.datazero"
          :image="require('@/assets/icons/empty-states/No-diary-entry.svg')"
        />

        <Button
          class="add-entry-button"
          icon="icon-plus"
          @clicked="entryButtonClicked"
        />
      </div>
    </template>
  </DiaryContainer>
</template>

<script>
import {
  DiaryContainer,
  Diary,
  Skeleton,
  LoaderSimple,
  Button
} from '@seliaco/red-panda'
import MoodBanner from './components/MoodBanner'
import DiaryCard from './components/DiaryCard'
import RecommendationBanner from '@/views/diary/components/RecommendationBanner'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import infinityScrollMixin from '@/mixins/infinity-scroll.mixin'
import { DiaryEvents } from '@/types/events'

export default {
  name: 'Diary',
  components: {
    DiaryContainer,
    DiaryCard,
    RecommendationBanner,
    MoodBanner,
    DataZeroSimple,
    Skeleton,
    LoaderSimple,
    Button
  },
  mounted () {
    this.getData()
    this.setListener('diary-content')
  },
  mixins: [infinityScrollMixin],
  data () {
    return {
      showModal: false,
      data: null,
      bannerData: null,
      entriesFiltered: [],
      lang: this.$store.getters.language.lang,
      loading: true,
      showDatazero: false,
      actions: {
        back: { callback: () => this.$router.push({ name: 'Home' }) }
      }
    }
  },
  computed: {
    dateRow () {
      return (date, entry, index) => {
        if (index > 0) {
          if (
            this.$moment(date).format('DD') ===
            this.$moment(entry[index - 1].created_at).format('hh:mm A')
          ) {
            return {
              number: '',
              name: ''
            }
          }
        }
        return {
          number: this.$moment(date).format('DD'),
          name: this.$moment(date).format('hA')
        }
      }
    }
  },
  methods: {
    async getData () {
      if (this.page === 1) {
        this.loading = true
      }
      const query = { page: this.page, per_page: 10 }

      if (this.preventMultipleCalls) {
        Diary.listEmotionEntries(query.page, query.per_page)
          .then((response) => {
            this.totalOfPages = response.headers.pages
            this.totalCount = response.headers.count

            if (this.page === 1) {
              this.data = response.data
            } else {
              this.data = [...this.data, ...response.data]
            }

            this.showDatazero = !this.data.length > 0
          })
          .catch(() => {
            this.$toast({
              text: this.$translations.error.default,
              severity: 'error'
            })
          })
      }
      this.bannerData = await Diary.getBannerContent('EMOTIONS_DIARY')

      await this.mapperEntriesByDate(this.data)
    },
    handleShowModal () {
      this.showModal = !this.showModal
    },
    mapperEntriesByDate (entries) {
      if (!entries) {
        this.entriesFiltered = []
        return
      }

      let dates

      entries.forEach((entry, index) => {
        const dateMoment =
          this.$moment.locale(this.lang) && this.$moment(entry.created_at)
        const label = dateMoment.format('MMMM YYYY')
        if (index === 0) {
          dates = {
            [label]: [entry]
          }
        } else if (dates[label]) {
          dates[label].push(entry)
        } else {
          dates = {
            ...dates,
            [label]: [entry]
          }
        }
      })

      this.entriesFiltered = { ...dates }

      this.loading = false
      this.infinityScroll = false
      this.preventMultipleCalls = false
    },
    entryButtonClicked () {
      this.$router
        .push({ name: 'DiaryEntry', query: { back: 'Diary' } })
        .then(() => {
          const body = {
            user: this.$store.getters['auth/user'],
            origin: 'Diary',
            component: 'Action_Button',
            mood: 0
          }
          DiaryEvents.Diary_Click_Create(body)
        })
    }
  },
  watch: {
    data (data) {
      if (data) {
        this.mapperEntriesByDate(data)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.add-entry-button
  position: fixed
  right: 20px
  bottom: 56px
  border-radius: var(--border-full)
  width: 70px
  height: 70px
  ::v-deep .btn
    .icon-plus
      position: relative
      font-size: 20px
      color: var(--white)
      top: 25px
      left: 25px

.skeleton-banner
  display: flex
  width: 100%
  max-width: 480px

.skeleton-content
  display: flex
  gap: 14px
  width: 100%
  .date
    display: flex
    flex-direction: column
    gap: 4px
  .cards
    display: flex
    width: 100%
    flex-direction: column
    gap: 8px
.entries
  &-entry
    &-month
      margin-bottom: 21px
      &::first-letter
        text-transform: uppercase
    &-day
      display: flex
      gap: 10px
      margin-top: 8px
      &-label
        width: 40px
        .body-small
          white-space: nowrap
          &::first-letter
            text-transform: uppercase
          &::last-letter
            display: none

@media (max-width: 840px)
  .add-entry-button
    width: 56px
    height: 56px
    .icon-plus
      top: 19px
      left: 18px

  .entries-entry-day
    gap: 8px
    &-label
      width: 38px
</style>
