<template>
  <div
    class="diarycard section-container-white"
    :class="isMoodOnly"
    @click="clickAction"
  >
    <div class="diarycard-content">
      <div class="heading-medium" v-text="title" />
      <div
        data-hj-suppress
        class="diarycard-content-text body-small"
        v-if="data.my_day"
        v-text="truncate"
      />

      <div
        class="diarycard-content-emotions"
        v-if="data.feeling_tags?.length > 0"
      >
        <Chip
          :text="emotionName(emotion.name)"
          color="purple"
          v-for="(emotion, index) in data.feeling_tags.slice(0, 5)"
          :key="index"
          size="small"
        />
        <Chip
          v-if="showMoreEmo"
          color="purple"
          :text="emotionsToShow"
          class="number-chip"
          size="small"
          emojiSize="12px"
        />
      </div>

      <div
        class="diarycard-content-activities"
        v-if="data.activity_tags?.length > 0"
      >
        <Chip
          :emoji="activity.code"
          color="blue"
          v-for="(activity, index) in data.activity_tags.slice(0, 5)"
          :key="index"
          size="small"
          emojiSize="12px"
        />
        <Chip
          v-if="showMoreAct"
          color="blue"
          :text="activitiesToShow"
          class="number-chip"
          size="small"
          emojiSize="12px"
        />
      </div>
    </div>

    <div class="diarycard-mood">
      <MoodButton
        :option="mood.find((option) => data.feeling_score === option.score)"
      />
    </div>
  </div>
</template>

<script>
import { Chip } from '@seliaco/red-panda'
import MoodButton from '@/views/diary/components/MoodButton'
import { DiaryEvents } from '@/types/events'
import { moodBannerEmojis } from '@/views/diary/configuration/configuration'

export default {
  name: 'DiaryCard',
  components: { MoodButton, Chip },
  props: {
    data: {
      id: String,
      my_day: String,
      name: String,
      activity_tags: Array,
      feeling_tags: Array,
      feeling_score: Number
    }
  },
  data () {
    return {
      lang: this.$store.getters.language.lang,
      showMoreEmo: false,
      showMoreAct: false,
      activitiesToShow: null,
      emotionsToShow: null,
      mood: moodBannerEmojis,
      created_at: String
    }
  },
  mounted () {
    this.lang = this.$store.getters.language.lang
    this.detailCard()
  },
  methods: {
    clickAction () {
      this.$router
        .push({
          name: 'EmotionDetail',
          params: { id: this.data.id }
        })
        .then(() => this.callSegmentEvent())
    },
    detailCard () {
      if (this.data?.feeling_tags?.length > 5) {
        this.showMoreEmo = true
        this.emotionsToShow = `+${this.data.feeling_tags.length - 5}`
      }
      if (this.data?.activity_tags?.length > 5) {
        this.showMoreAct = true
        this.activitiesToShow = `+${this.data.activity_tags.length - 5}`
      }
    },
    callSegmentEvent () {
      const body = {
        user: this.$store.getters['auth/user'],
        activities: this.data.activity_tags,
        emotions: this.data.feeling_tags,
        mood: this.data.feeling_score,
        date: this.data.created_at.toLocaleString()
      }
      DiaryEvents.Diary_Click_Open(body)
    }
  },
  computed: {
    title () {
      const mood = this.mood?.find(
        (option) => this.data?.feeling_score === option.score
      )

      return this.$translations.diary['mood-banner'].feelings[mood.feeling]
    },
    truncate () {
      return this.data.my_day.length > 80
        ? `${this.data.my_day.slice(0, 80)}...`
        : this.data.my_day
    },
    isMoodOnly () {
      if (
        (!this.data.my_day || this.data.my_day?.length === 0) &&
        (!this.data.feeling_tags || this.data.feeling_tags?.length === 0) &&
        (!this.data.activity_tags || this.data.activity_tags?.length === 0)
      ) {
        return 'diarycard-mood-only'
      }

      return ''
    },
    emotionName () {
      return (name) => {
        return name[this.lang] || name.es || name.en
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$squareSize: 56px

.diarycard
  display: flex
  justify-content: space-between
  align-items: center
  gap: 15px
  width: 100%
  &-mood
    height: 100%
    align-self: flex-start
    ::v-deep .option-box
      border: none
      background: none
      padding: 0
      width: $squareSize
      height: $squareSize
      cursor: default
      .option
        width: $squareSize
        height: $squareSize
        .item
          font-size: var(--xl-4-5)
  &-content
    display: flex
    flex-flow: column
    gap: 10px
    max-width: 335px
    &-text
      word-break: break-all
      overflow: hidden
    &-emotions,
    &-activities
      display: flex
      gap: 5px
      flex-wrap: wrap
      max-width: 400px
    &-activities
      .chip-medium
        padding: 8px 4px
.diarycard-mood-only
  padding: 4px 16px!important
</style>
