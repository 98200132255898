<template>
  <div class="recommendation-banner-container padding-all">
    <div class="content">

      <div class="image-box">
        <img :src="bannerImage" alt="plant">
      </div>

      <div class="text">
        <div class="content-small text-purple-40" v-text="title"/>
        <div class="body text-white" v-text="content" />
      </div>

    </div>

    <Button
      v-if="showButton"
      class="text-white"
      :text="buttonText"
      :type="'outline'"
      :color="'gray'"
    />
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'
export default {
  name: 'RecommendationBanner',
  components: { Button },
  props: {
    title: String,
    content: String,
    showButton: {
      type: Boolean,
      default: false
    },
    buttonText: String,
    iconUrl: String
  },
  computed: {
    bannerImage () {
      return this.iconUrl
    }
  }
}
</script>

<style lang="sass" scoped>
.recommendation-banner-container
  display: flex
  flex-direction: column
  gap: 10px
  background: var(--purple)
  border-bottom: 2px solid var(--dark-purple)
  border-radius: var(--border-3)
  width: 100%
  max-width: 480px
  width: 100%
  .content
    display: flex
    align-items: center
    gap: 16px
    width: 100%
    .image-box
      display: flex
      justify-content: center
      max-width: 90px
      height: 90px
      background: var(--purple-5)
      border-radius: var(--border-3)
      img
        border-radius: var(--border-3)
    .text
      display: flex
      gap: 4px
      flex-direction: column

@media (max-width: 840px)
  .recommendation-banner-container
    .content
      .image-box
        height: 72px
        min-width: 72px
        width: 72px
</style>
